import React, { useEffect } from "react";
import styles from "./transactions.module.scss";

import FinancialInfoDashboard from "../../_components/finance/dashboard";

import useIsCleaner from "../../_utils/isCleaner";

import t from "../../_config/constants";

const Transactions = () => {
  const isCleaner = useIsCleaner();
  useEffect(() => {
    isCleaner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.contentWrapper}>
        <div className={styles.tabs}>
          <div className={styles.tabActive}>
            <p>{t.transactions.text_financial_info}</p>
          </div>
        </div>
        <div className={styles.content}>
          <FinancialInfoDashboard />
        </div>
      </div>
    </div>
  );
};

export default Transactions;
