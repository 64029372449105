import React, { useEffect, useState } from "react";
import styles from "./rented.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { getDropdownListMonths } from "../../../_utils/mapTransactions";
import {
  getDropdownListYears,
  getWeeksOfYear,
} from "../../../_utils/getReportsDateOptions";
import { getStatistics } from "../../../_services/statistics.service";
import Selector from "../../selector";
import Loading from "../../loading";
import LineChart from "../../charts/line";
import { mainActions } from "../../../_store/actions/main.actions";
import { makeSelectMap } from "../../../_utils/utils";

const CheckoutsChart = ({
  setDisabled,
  selectedStoreId = "",
  setSelectedRestaurant,
  setLogsDate,
}) => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();

  const [loadingData, setLoadingData] = useState(true);
  const [restaurantOptions, setRestaurantOptions] = useState([]);
  const [monthsOptions, setMonthsOptions] = useState([]);
  const [yearsOptions, setYearsOptions] = useState([]);
  const [weeksOptions, setWeeksOptions] = useState([]);
  const [numberOfReturned, setNumberOfReturned] = useState();
  const [containerTypeOptions, setContainerTypeOptions] = useState([]);

  const [statisticsFilter, setStatisticsFilter] = useState({
    period: "daily",
    startOfWeek: "",
    month: "",
    year: "",
    storeId: "",
    containerTypeId: "",
  });
  const [arrayForLineChart, setarrayForLineChart] = useState([]);
  useEffect(() => {
    const arrYears = getDropdownListYears(2021);
    const year = arrYears[0].value;
    setYearsOptions(arrYears);
    setStatisticsFilter((prev) => ({
      ...prev,
      year: year,
    }));
    setLogsDate && setLogsDate((prev) => ({ ...prev, year }));
  }, []);

  const periodOptions = [
    { value: "daily", label: "Daily" },
    { value: "weekly", label: "Weekly" },
    { value: "monthly", label: "Monthly" },
    { value: "yearly", label: "Yearly" },
    { value: "all", label: "All Time" },
  ];
  useEffect(() => {
    if (statisticsFilter.year) {
      const arr = getDropdownListMonths(statisticsFilter.year, true);

      setMonthsOptions(arr);
      const weeks = getWeeksOfYear(statisticsFilter.year);
      setWeeksOptions(weeks);
      setStatisticsFilter((prev) => ({
        ...prev,
        month: arr[0]?.value,
        startOfWeek: weeks[0]?.value,
      }));
      setLogsDate &&
        setLogsDate((prev) => ({
          ...prev,
          month: arr[0]?.value,
          startOfWeek: weeks[0]?.value,
        }));
    }
  }, [statisticsFilter.year]);

  useEffect(() => {
    setLoadingData(true);
    setDisabled && setDisabled(true);
    const getData = async (data, storeId) => {
      storeId && (data.storeId = storeId);
      const response = await getStatistics({
        mode: "get-checkout-statistics",
        filter: data,
      });
      if (response) {
        setarrayForLineChart(response.array || []);
        setNumberOfReturned(response.total);
      }
      setLoadingData(false);
      setDisabled && setDisabled(false);
    };
    if (
      store.auth &&
      store.auth.user &&
      store.auth.user.regionId &&
      statisticsFilter.year &&
      ((statisticsFilter.period === "weekly" && statisticsFilter.startOfWeek) ||
        statisticsFilter.month ||
        statisticsFilter.month === 0) &&
      statisticsFilter.period
    ) {
      getData(statisticsFilter, selectedStoreId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.auth && store.auth.user, statisticsFilter, selectedStoreId]);
  useEffect(() => {
    const getRestaurants = async (regionId, subregionId) => {
      let filter = [{ archived: { $ne: true } }];
      if (regionId && regionId !== "*") filter.push({ regionId });
      if (subregionId && subregionId !== "*") filter.push({ subregionId });
      let data = await dispatch(
        mainActions.run("profiles", "restaurant", "all", { filter })
      );
      if (data) {
        let arr = makeSelectMap(data);
        let filterOptions = [...arr];
        filterOptions.unshift({ label: "All Stores", value: "" });
        setRestaurantOptions(filterOptions);
      }
    };
    const fetchAllContainerTypes = async (regionId, subregionId) => {
      let filter = [];
      if (regionId && regionId !== "*") filter.push({ regionId });
      if (subregionId && subregionId !== "*") filter.push({ subregionId });
      let data = await dispatch(
        mainActions.run("data", "containerType", "all", {
          filter,
        })
      );
      let arr = makeSelectMap(data);
      let filterOptions = [...arr];
      filterOptions.unshift({ label: "All Containers", value: "" });
      setContainerTypeOptions(filterOptions);
    };
    if (store.auth && store.auth.user && store?.auth?.user?.regionId) {
      getRestaurants(
        store?.auth?.user?.regionId,
        store?.auth?.user?.subregionId
      );
      fetchAllContainerTypes(
        store?.auth?.user?.regionId,
        store?.auth?.user?.subregionId
      );
    }
  }, [store.auth && store.auth.user]);

  const getSelectedValue = (options, value) => {
    const selectedData = [];
    options &&
      options.forEach((obj) => {
        if (obj.value === value) {
          selectedData.push(obj);
        }
      });
    return selectedData;
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        {!selectedStoreId && (
          <div className={styles.select}>
            <Selector
              label=""
              placeholder="All Stores"
              multiselect={false}
              selectClassname="selectGray"
              options={restaurantOptions}
              disabled={loadingData}
              handleChangeSelect={(e) => {
                setSelectedRestaurant && setSelectedRestaurant(e.value);
                setStatisticsFilter((prev) => ({
                  ...prev,
                  storeId: e.value,
                }));
              }}
            />
          </div>
        )}
        <div className={styles.select}>
          <Selector
            label=""
            placeholder="All Containers"
            multiselect={false}
            selectClassname="selectGray"
            options={containerTypeOptions}
            disabled={loadingData}
            handleChangeSelect={(e) =>
              setStatisticsFilter((prev) => ({
                ...prev,
                containerTypeId: e.value,
              }))
            }
          />
        </div>
        <div className={styles.row}>
          <div className={styles.icon}>
            <div>
              <span>{numberOfReturned || "0"}</span>
            </div>
            <span>Rented</span>
          </div>
          <div className={styles.selectDate}>
            <div className={styles.selectPeriod}>
              <Selector
                label=""
                multiselect={false}
                selectClassname="selectGray"
                selectedOption={getSelectedValue(
                  periodOptions,
                  statisticsFilter.period
                )}
                handleChangeSelect={(e) => {
                  setStatisticsFilter((prev) => ({
                    ...prev,
                    period: e.value,
                  }));
                  setLogsDate &&
                    setLogsDate((prev) => ({
                      ...prev,
                      period: e.value,
                    }));
                }}
                options={periodOptions}
                disabled={loadingData}
              />
            </div>
            {statisticsFilter.period === "weekly" ? (
              <div className={`${styles.selectWeek}`}>
                <Selector
                  label=""
                  multiselect={false}
                  selectClassname="selectGray"
                  selectedOption={getSelectedValue(
                    weeksOptions,
                    statisticsFilter.startOfWeek
                  )}
                  handleChangeSelect={(e) => {
                    setStatisticsFilter((prev) => ({
                      ...prev,
                      startOfWeek: e.value,
                    }));
                    setLogsDate &&
                      setLogsDate((prev) => ({
                        ...prev,
                        startOfWeek: e.value,
                      }));
                  }}
                  options={weeksOptions}
                  disabled={loadingData}
                />
              </div>
            ) : (
              <div
                className={`${styles.selectMonth} ${
                  statisticsFilter.period !== "daily"
                    ? styles.selectDisabled
                    : ""
                }`}
              >
                <Selector
                  label=""
                  multiselect={false}
                  selectClassname="selectGray"
                  selectedOption={getSelectedValue(
                    monthsOptions,
                    statisticsFilter.month
                  )}
                  handleChangeSelect={(e) => {
                    setStatisticsFilter((prev) => ({
                      ...prev,
                      month: e.value,
                    }));
                    setLogsDate &&
                      setLogsDate((prev) => ({
                        ...prev,
                        month: e.value,
                      }));
                  }}
                  options={monthsOptions}
                  disabled={loadingData || statisticsFilter.period !== "daily"}
                />
              </div>
            )}
            <div
              className={`${styles.selectYear} ${
                ["yearly", "all"].includes(statisticsFilter.period)
                  ? styles.selectDisabled
                  : ""
              }`}
            >
              <Selector
                label=""
                multiselect={false}
                selectClassname="selectGray"
                selectedOption={getSelectedValue(
                  yearsOptions,
                  statisticsFilter.year
                )}
                handleChangeSelect={(e) => {
                  setStatisticsFilter((prev) => ({
                    ...prev,
                    year: e.value,
                  }));
                  setLogsDate &&
                    setLogsDate((prev) => ({
                      ...prev,
                      year: e.value,
                    }));
                }}
                options={yearsOptions}
                disabled={
                  loadingData ||
                  ["yearly", "all"].includes(statisticsFilter.period)
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.chartWrapper}>
        {loadingData && (
          <div className={styles.loading}>
            <Loading width={50} height={50} />
          </div>
        )}
        {!loadingData && !arrayForLineChart.length && (
          <div className={styles.noReturnedMsg}>
            There are no rented containers
          </div>
        )}
        {!loadingData && arrayForLineChart.length > 0 && (
          <div className={styles.bigChartSection}>
            <LineChart arrayForLineChart={arrayForLineChart} />
          </div>
        )}
      </div>
    </div>
  );
};

export default CheckoutsChart;
