import React from "react";
import styles from "./reusers.module.scss";
import RegistrationChart from "../../../_components/subregionPanel/reusers/registrationChart";
import ReusersList from "../../../_components/subregionPanel/reusers/reusersList";
const Reusers = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.contentContainer}>
        <RegistrationChart />
        <div className={styles.membersData}>
          <ReusersList />
        </div>
      </div>
    </div>
  );
};

export default Reusers;
