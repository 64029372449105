import React, { useEffect } from "react";
import styles from "./table.module.scss";
import moment from "moment";
import { HiDownload } from "react-icons/hi";
import { BlobProvider } from "@react-pdf/renderer";

import Loading from "../../../loading";
import Button from "../../../button";
import InvoiceDoc from "../../../restaurant/pdfInvoice";

import arrowUp from "../../../../_assets/icons/arrowUp.svg";
import arrowDown from "../../../../_assets/icons/arrowDown.svg";
import upDownArrow from "../../../../_assets/icons/upDownArrow.svg";

import {
  capitalizeWord,
  getPaymentType,
  getTransactionsType,
  getUserName,
} from "../../../../_utils/utils";
import useSortableData from "../../../../_utils/useSortableData";

import t from "../../../../_config/constants";
import { useDispatch } from "react-redux";
import { modalActions } from "../../../../_store/actions/modal.actions";
import TransactionDetails from "../transactionDetails";

const TransactionsTable = ({
  transactions,
  loadingData,
  changeSortConfig,
  setRefresh,
}) => {
  const dispatch = useDispatch();
  const { handleSort, sortConfig } = useSortableData(transactions);
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return upDownArrow;
    }
    return sortConfig.key === name
      ? sortConfig.direction === "ascending"
        ? arrowUp
        : arrowDown
      : upDownArrow;
  };
  useEffect(() => {
    changeSortConfig(sortConfig);
  }, [sortConfig]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <p className={styles.blockUser}>Reuser</p>
        <p
          className={styles.blockUserType}
          onClick={() => (loadingData ? null : handleSort("user.type"))}
        >
          User Type
          <Button btnClass="btnBack" iconLeft={getClassNamesFor("user.type")} />
        </p>

        <p
          className={styles.blockType}
          onClick={() => (loadingData ? null : handleSort("transactionType"))}
        >
          {t.common.text_transaction_type}
          <Button
            btnClass="btnBack"
            iconLeft={getClassNamesFor("transactionType")}
          />
        </p>
        <p
          className={styles.blockType}
          onClick={() => (loadingData ? null : handleSort("paymentType"))}
        >
          {t.common.text_payment_type}
          <Button
            btnClass="btnBack"
            iconLeft={getClassNamesFor("paymentType")}
          />
        </p>
        <p
          className={styles.blockDate}
          onClick={() => (loadingData ? null : handleSort("createdAt"))}
        >
          {t.common.text_date}
          <Button btnClass="btnBack" iconLeft={getClassNamesFor("createdAt")} />
        </p>
        <p
          className={styles.blockStatus}
          onClick={() => (loadingData ? null : handleSort("status"))}
        >
          {t.common.text_th_status}
          <Button btnClass="btnBack" iconLeft={getClassNamesFor("status")} />
        </p>
        <p
          className={styles.blockAmount}
          onClick={() => (loadingData ? null : handleSort("amount"))}
        >
          {t.common.text_th_amount}
          <Button btnClass="btnBack" iconLeft={getClassNamesFor("amount")} />
        </p>
      </div>
      {loadingData && (
        <div className={styles.loader}>
          <Loading width={75} height={75} />
        </div>
      )}
      {!loadingData && !transactions.length && (
        <div className={styles.noTransactionsMsg}>
          <span>{t.common.text_no_transactions}</span>
        </div>
      )}
      {!loadingData &&
        transactions.length > 0 &&
        transactions.map((item) => {
          return (
            <div
              className={styles.block}
              key={item._id}
              onClick={() =>
                dispatch(
                  modalActions.openModal(
                    <TransactionDetails
                      transaction={item}
                      setRefresh={setRefresh}
                    />,
                    "",
                    "md"
                  )
                )
              }
            >
              <p className={styles.blockUser}>{getUserName(item)}</p>
              <p className={styles.blockUserType}>
                {item?.user?.type === "restaurant" ? "Store" : "Member"}
              </p>
              <p className={styles.blockType}>
                {getTransactionsType(item.transactionType)}
                {item.transactionType === "RESTAURANT_INVOICE" && (
                  <BlobProvider document={<InvoiceDoc invoice={item} />}>
                    {({ url }) => (
                      <a className={styles.svg} href={url} target="_blank">
                        <HiDownload />
                      </a>
                    )}
                  </BlobProvider>
                )}
              </p>
              <p className={styles.blockType}>
                {getPaymentType(item.paymentType)}
              </p>
              <p className={styles.blockDate}>
                {moment(item.createdAt).format("MM/DD/YYYY")}
              </p>
              <p className={styles.blockStatus}>
                {capitalizeWord(item.status)}
              </p>
              <p className={styles.blockAmount}>
                ${item.amount && item.amount != "NaN" ? item.amount : "0.00"}{" "}
                {item.currency?.toUpperCase()}
              </p>
            </div>
          );
        })}
    </div>
  );
};
export default TransactionsTable;
