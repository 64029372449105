import t from "../_config/constants";

export const isObjectEmpty = (obj) => {
  if (!obj) return true;
  return Object.keys(obj).length === 0;
};

export const makeSelectMap = (arr) => {
  let result = [];
  if (arr && arr.length > 0) {
    arr.forEach((item) => {
      let obj = {
        label: item.name || item.fullName,
        value: item._id,
      };
      result.push(obj);
    });
  } else {
    return [];
  }
  return result;
};
export const createCumulativeArray = (arr) => {
  let cumulativeSum = 0;
  return arr.map((item) => {
    cumulativeSum += item.value;
    return cumulativeSum;
  });
};
export const getHoursInADay = () => {
  const result = [];
  const array = Array.from({ length: 24 }, (_, i) => i);

  for (let i = 0; i < array.length; i++) {
    if (i === 0) result.push("12 AM");
    else if (i < 12) result.push(i + " AM");
    else if (i === 12) result.push("12 PM");
    else result.push(i - 12 + " PM");
  }

  return result;
};
export const getPaymentType = (type) => {
  let val = "";
  switch (type) {
    case "CARD":
      val = "Card";
      break;
    case "TRANSACT":
      val = "Transact";
      break;
    case "CBORD":
      val = "CBORD";
      break;
    default:
      val = type;
  }
  return val;
};
export const getTransactionsType = (type) => {
  let val = "";
  switch (type) {
    case "ADMIN_CHARGE":
      val = "Admin Charge";
      break;
    case "CHARGE_LATE_FEES":
      val = "Penalty Charge";
      break;
    case "REFUND":
      val = "Refund";
      break;
    case "RESTAURANT_INVOICE":
      val = "Monthly Report";
      break;
    case "SUBSCRIPTION":
      val = "Subscription";
      break;
    case "SUBSCRIPTION_RATE":
      val = "Subscription Rate";
      break;
    case "CHARGE_CONTAINER":
      val = "Charged Container";
      break;
    case "PER_USE_CHARGE":
      val = "Per Use Charge";
      break;
    case "PER_USE_INVOICE":
      val = "Per Use Report";
      break;
    case "PRE_AUTH_PAYMENT_INTENT":
      val = "Pre Authorized Charge";
      break;
    default:
      val = type;
  }
  return val;
};

export const getUserName = (transaction) => {
  let userName = "";
  switch (transaction.paymentType) {
    case "TRANSACT":
      userName = "Transact Reuser";
      break;
    case "CBORD":
      userName = "CBORD Reuser";
      break;
    case "CARD":
      userName =
        transaction.user?.name ||
        transaction.user?.fullName ||
        transaction.user?.email ||
        transaction.user?.phone ||
        transaction.user?.customer;
  }
  return userName;
};
export const termRegex = (term) => {
  let res = term.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  return res;
};

export const capitalizeWord = (term) => {
  if (term === "SUCCESSFULL") term = term.slice(0, -1);
  return term.charAt(0).toUpperCase() + term.slice(1).toLowerCase();
};

export const trimDecimalNumbers = (value) => {
  let retVal = "";
  const chars = value.split(".");
  if (chars.length > 1) {
    retVal = chars[0];
    while (retVal[0] == 0) {
      retVal = retVal.slice(1);
    }
    retVal = (retVal ? retVal : 0) + "." + chars[1];
  } else {
    retVal = value;
    while (retVal[0] == 0) {
      retVal = retVal.slice(1);
    }
    retVal = retVal ? retVal : 0;
  }
  retVal = Number(retVal).toFixed(2);
  return retVal;
};

export const compareUniqueContainerIds = (a, b) => {
  const regex = /\/(\d+)$/; // Regular expression to extract the number at the end of the string
  const aNum = parseInt(a.uniqueContainerId.match(regex)[1]);
  const bNum = parseInt(b.uniqueContainerId.match(regex)[1]);

  return aNum - bNum;
};

export const days = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

export const validateURL = (stringUrl) => {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  let url;
  try {
    url = new URL(stringUrl);
  } catch (e) {
    return false;
  }
  return (
    (url.protocol === "http:" || url.protocol === "https:") &&
    !!pattern.test(stringUrl)
  );
};

export const countryOptions = [
  { label: "Canada", value: "Canada" },
  { label: "USA", value: "United States" },
];

export const dummyCurrencies = [
  {
    label: t.currency.text_usd,
    value: "usd",
  },
  {
    label: t.currency.text_can,
    value: "cad",
  },
];
export const chargingOptions = [
  {
    label: "Charge full price",
    value: "full",
  },
  {
    label: "Late fee per day",
    value: "penalties",
  },
];
export const appOptions = [
  { label: "User App", value: "user" },
  { label: "Store App", value: "restaurant" },
];
