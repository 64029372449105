import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { mainActions } from "../../../../_store/actions/main.actions";
import toastService from "../../../../_services/toast.service";
import styles from "./form.module.scss";
import Input from "../../../../_components/input";
import Selector from "../../../../_components/selector";
import Button from "../../../../_components/button";
import t from "../../../../_config/constants";
import ConfirmModal from "../../../modal/confirmModal";
import {
  makeSelectMap,
  trimDecimalNumbers,
  validateURL,
} from "../../../../_utils/utils";
import trimObjectProps from "../../../../_utils/trimObjectProps";
import { isObjectEmpty } from "../../../../_utils/utils";
import UploadAvatar from "../../../uploadAvatar";
import { uploadToS3 } from "../../../../_utils/auth/uploadPhoto";
import { amazonUrl, amazonUrlResized } from "../../../../environment";
const CreateTypeForm = ({
  refetch,
  regionId,
  selectedContainer,
  setSelectedContainer,
  activeTab,
}) => {
  const [errorMsg, setErrorMsg] = useState(false);
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);
  const store = useSelector((state) => state);
  const [regions, setRegions] = useState([]);
  const [regionOptions, setRegionOptions] = useState([]);
  const [subregions, setSubregions] = useState([]);
  const [subregionOptions, setSubregionOptions] = useState([]);

  const [editing, setEditing] = useState(false);
  const [data, setData] = useState({
    name: "",
    material: "",
    size: { length: "", width: "", height: "" },
    volume: "",
    currency: "",
    pricing: "",
    replenishment: "0",
    pricePerUse: "",
    regionId: "",
    subregionId: "",
    price: "",
    late_fee: "",
    iconURL: "",
    redirectURL: "",
    wasteCoef: "0.05",
    waterCoef: "1",
    ghgCoef: "0.2",
  });
  const [image, setImage] = useState({
    uri: data.iconURL ? data.iconURL : "",
  });
  const [showModalDelete, setShowModalDelete] = useState(false);
  useEffect(() => {
    if (!isObjectEmpty(selectedContainer)) {
      setImage({
        uri: selectedContainer.iconURL ? selectedContainer.iconURL : "",
      });
      setData({
        ...selectedContainer,
        price: selectedContainer.price ? selectedContainer.price : "",
        late_fee: selectedContainer.late_fee ? selectedContainer.late_fee : "",
        pricePerUse: selectedContainer.pricePerUse
          ? selectedContainer.pricePerUse
          : "",
        wasteCoef: selectedContainer.wasteCoef || "0.05",
        waterCoef: selectedContainer.waterCoef || "1",
        ghgCoef: selectedContainer.ghgCoef || "0.2",
      });
      setEditing(true);
    }
  }, [selectedContainer]);

  const [error, setError] = useState({
    name: false,
    material: false,
    size: { width: false, length: false, height: false },
    volume: false,
    currency: false,
    pricing: false,
    replenishment: false,
    regionId: false,
    subregionId: false,
    price: false,
    late_fee: false,
    pricePerUse: false,
    redirectURL: false,
    wasteCoef: false,
    waterCoef: false,
    ghgCoef: false,
  });
  useEffect(() => {
    const fetchSubregions = async () => {
      const response = await dispatch(
        mainActions.run("data", "subregion", "all", {})
      );
      setSubregions(response);
    };
    if (regionId) {
      if (regionId != "*") {
        setData((prev) => ({ ...prev, regionId }));
        setError((error) => ({ ...error, regionId: false }));
      } else {
        const fetchRegions = async () => {
          let response = await dispatch(
            mainActions.run("data", "region", "all", {})
          );
          setRegions(response);
          let arr = makeSelectMap(response);
          setRegionOptions(arr);
        };
        fetchRegions();
      }
      fetchSubregions();
    }
  }, [regionId]);
  useEffect(() => {
    if (data.regionId) {
      const filteredSubregions = subregions.filter(
        (item) => item.regionId === data.regionId
      );
      const arr = makeSelectMap(filteredSubregions);
      arr.length && arr.push({ label: "-", value: "" });
      setSubregionOptions(arr);
    }
  }, [subregions]);

  useEffect(() => {
    if (data.regionId && subregions.length) {
      const filteredSubregions = subregions.filter(
        (item) => item.regionId === data.regionId
      );
      const arr = makeSelectMap(filteredSubregions);
      arr.length && arr.push({ label: "-", value: "" });
      setSubregionOptions(arr);
      setData((prev) => ({ ...prev, subregionId: "" }));
    }
  }, [data.regionId]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const iconUrl = await uploadToS3(image);

      setDisabled(true);
      let obj = {
        ...data,
        iconURL: iconUrl ? amazonUrl + iconUrl : data.iconURL,
        iconURLResized: iconUrl
          ? amazonUrlResized + iconUrl
          : data.iconURLResized,
        pricePerUse: trimDecimalNumbers(data.pricePerUse),
        price: trimDecimalNumbers(data.price),
        pricing: trimDecimalNumbers(data.pricing),
        late_fee: trimDecimalNumbers(data.late_fee),
        wasteCoef: trimDecimalNumbers(data.wasteCoef),
        waterCoef: trimDecimalNumbers(data.waterCoef),
        ghgCoef: trimDecimalNumbers(data.ghgCoef),
      };
      if (editing) {
        let response = await dispatch(
          mainActions.run("data", "containerType", "update", obj)
        );
        if (response) {
          toastService.show(
            "success",
            t.containerManagement.create.text_successfully_edited_type
          );
        }
      } else {
        let response = await dispatch(
          mainActions.run("data", "containerType", "new", obj)
        );
        if (response)
          toastService.show(
            "success",
            t.containerManagement.create.text_successfully_created_type
          );
      }
      refetch(regionId);
      clearData();
      setEditing(false);
    } else {
      toastService.show("error", `${t.common.text_toast_all_fields_required}`);
      setErrorMsg(true);
    }
    setDisabled(false);
  };
  const handleChooseImg = (e) => {
    e.preventDefault();
    let set = {
      uri: URL.createObjectURL(e.target.files[0]),
      type: e.target.files[0].type,
      file: e.target.files[0],
    };
    setImage(set);
  };
  const handleChange = (e) => {
    setErrorMsg(false);
    let { name, value } = e.target;
    if (name === "volume" && value.slice(0, 1) === "0") {
      value = value.slice(1);
    }
    setData((data) => ({ ...data, [name]: value }));
    setError((error) => ({ ...error, [name]: !value }));
  };
  const handleChangeSize = (e) => {
    setErrorMsg(false);
    let { name, value } = e.target;
    if (value.slice(0, 1) === "0") {
      value = value.slice(1);
    }
    setData((data) => ({
      ...data,
      size: { ...data.size, [name]: value },
    }));
    setError((error) => ({
      ...error,
      size: { ...error.size, [name]: !value },
    }));
  };
  const handleSelect = (e, name) => {
    setErrorMsg(false);
    if (name == "subregionId") {
      setData((data) => ({ ...data, subregionId: e.value }));
      setError((error) => ({ ...error, subregionId: false }));
    } else {
      setData((data) => ({
        ...data,
        currency: e.value,
      }));
      setError((error) => ({ ...error, currency: false }));
    }
  };

  const clearData = () => {
    setData({
      name: "",
      material: "",
      size: "",
      volume: "",
      currency: "",
      pricing: "",
      pricePerUse: "",
      replenishment: "0",
      price: "",
      late_fee: "",
      iconURL: "",
      redirectURL: "",
      wasteCoef: "0.05",
      waterCoef: "1",
      ghgCoef: "0.2",
    });
    setImage({ uri: "" });
    setSelectedContainer({});
  };
  const handleCancel = (e) => {
    e.preventDefault();
    clearData();
    setError({
      name: false,
      material: false,
      size: false,
      volume: false,
      currency: false,
      pricing: false,
      replenishment: false,
      regionId: false,
      price: false,
      late_fee: false,
      pricePerUse: false,
      redirectURL: false,
      wasteCoef: false,
      waterCoef: false,
      ghgCoef: false,
    });
    setEditing(false);
    setErrorMsg(false);
  };
  const handleSelectRegion = (e) => {
    setErrorMsg(false);
    setData((data) => ({
      ...data,
      regionId: e.value,
    }));
    setError((error) => ({ ...error, regionId: false }));
  };
  const getSelectedValue = (options, value) => {
    const selectedData = [];
    options &&
      options.forEach((obj) => {
        if (obj.value === value) {
          selectedData.push(obj);
        }
      });
    return selectedData;
  };

  const validateForm = () => {
    trimObjectProps(data);
    setError({
      name: !data.name,
      material: !data.material,
      size: {
        length: !data.size.length,
        width: !data.size.width,
        height: !data.size.height,
      },
      volume: !data.volume,
      currency: !data.currency,
      pricing: !data.pricing,
      replenishment: !data.replenishment,
      regionId: !data.regionId,
      subregionId: !data.subregionId,
      price: !data.price,
      late_fee: !data.late_fee,
      pricePerUse: !data.pricePerUse,
      redirectURL: !data.redirectURL || !validateURL(data.redirectURL),
      wasteCoef: !data.wasteCoef,
      waterCoef: !data.waterCoef,
      ghgCoef: !data.ghgCoef,
    });
    return (
      data.name &&
      data.material &&
      data.size.length &&
      data.size.width &&
      data.size.height &&
      data.volume &&
      data.currency &&
      data.pricing &&
      data.replenishment &&
      data.regionId &&
      data.subregionId &&
      data.late_fee &&
      data.price &&
      data.pricePerUse &&
      data.redirectURL &&
      validateURL(data.redirectURL) &&
      data.wasteCoef &&
      data.waterCoef &&
      data.ghgCoef
    );
  };
  const dummyCurrencies = [
    {
      label: t.currency.text_usd,
      value: "USD",
    },
    {
      label: t.currency.text_can,
      value: "CAN",
    },
  ];
  const handleInputNumber = (e) => {
    const numbers = "0123456789.";
    if (!numbers.includes(e.key)) {
      e.preventDefault();
    }
  };

  const handleDelete = async (answer) => {
    if (answer) {
      await dispatch(
        mainActions.run(
          "data",
          "containerType",
          "delete",
          { _id: selectedContainer._id },
          {
            message: `${t.containerManagement.create.text_successfully_deleted_type}`,
          }
        )
      );
    }
    setShowModalDelete(false);
    refetch(regionId);
    setEditing(false);
    clearData();
  };
  const openDeleteModal = async (e) => {
    e.preventDefault();
    setShowModalDelete(true);
  };
  return (
    <>
      {showModalDelete && (
        <ConfirmModal
          show={showModalDelete}
          handleYesNoAnswer={handleDelete}
          question={t.containerManagement.create.text_modal_delete_type}
        />
      )}
      {activeTab === "new" || !isObjectEmpty(selectedContainer) ? (
        <form onSubmit={handleSubmit} className={styles.form}>
          <h4>Info</h4>
          <div className={styles.nameAndRegion}>
            <div className={styles.name}>
              <Input
                label={t.containerManagement.create.text_input_name}
                error={error.name}
                name="name"
                inputClass="inputForm"
                type="text"
                value={data.name}
                placeholder={
                  t.containerManagement.create.text_input_name_placeholder
                }
                onChange={handleChange}
                disabled={editing}
              />
              <div className={styles.avatar}>
                <UploadAvatar
                  type="icon"
                  uri={image.uri}
                  setImg={handleChooseImg}
                />
              </div>
            </div>
            {regionId === "*" && (
              <div className={styles.selectRegion}>
                <Selector
                  label="Container Region"
                  options={regionOptions}
                  error={error.regionId}
                  multiselect={false}
                  selectedOption={getSelectedValue(
                    regionOptions,
                    data.regionId
                  )}
                  handleChangeSelect={(e) => handleSelectRegion(e)}
                  selectClassname="selectGray"
                  placeholder={t.common.input.text_select_region}
                  disabled={editing && data.regionId}
                />
              </div>
            )}
          </div>
          <div className={styles.selectRegion}>
            <Selector
              label={"Container Subregion"}
              options={subregionOptions}
              multiselect={false}
              selectedOption={getSelectedValue(
                subregionOptions,
                data.subregionId
              )}
              handleChangeSelect={(e) => handleSelect(e, "subregionId")}
              selectClassname="selectGray"
              placeholder={"Select Subregion"}
              error={error.subregionId}
            />
          </div>
          <div className={styles.redirectURL}>
            <Input
              label={t.containerManagement.add.text_redirect_url}
              error={error.redirectURL}
              name="redirectURL"
              inputClass="inputForm"
              type="text"
              value={data.redirectURL}
              placeholder={t.restaurant.text_url_example}
              onChange={handleChange}
              errorMsg={"Please enter valid URL"}
            />
          </div>
          <div className={styles.info}>
            <Input
              label={t.containerManagement.create.text_input_material}
              error={error.material}
              name="material"
              inputClass="inputForm"
              type="text"
              value={data.material}
              placeholder={
                t.containerManagement.create.text_input_material_placeholder
              }
              onChange={handleChange}
            />
            <Input
              label={t.containerManagement.create.text_input_volume}
              error={error.volume}
              name="volume"
              inputClass="inputForm"
              type="text"
              value={data.volume}
              placeholder={
                t.containerManagement.create.text_input_volume_placeholder
              }
              desc="oz"
              maxLength={30}
              onChange={handleChange}
              onKeyPress={handleInputNumber}
            />
          </div>
          <h4>{t.containerManagement.create.text_input_size}</h4>
          <div className={styles.infoSize}>
            <Input
              label={t.containerManagement.create.text_input_length}
              error={error.size.length}
              name="length"
              inputClass="inputForm"
              type="number"
              value={data.size.length}
              desc="mm"
              placeholder="0"
              onChange={handleChangeSize}
              onKeyPress={handleInputNumber}
            />
            <Input
              label={t.containerManagement.create.text_input_width}
              error={error.size.width}
              name="width"
              inputClass="inputForm"
              type="number"
              placeholder="0"
              value={data.size.width}
              desc="mm"
              onChange={handleChangeSize}
              onKeyPress={handleInputNumber}
            />
            <Input
              label={t.containerManagement.create.text_input_height}
              error={error.size.height}
              name="height"
              placeholder="0"
              inputClass="inputForm"
              type="number"
              value={data.size.height}
              desc="mm"
              onChange={handleChangeSize}
              onKeyPress={handleInputNumber}
            />
          </div>
          <h4>
            {t.containerManagement.create.text_default_price}{" "}
            {error.currency && <span>*</span>}
          </h4>
          <Selector
            label=""
            placeholder={t.containerManagement.create.text_select_currency}
            multiselect={false}
            selectClassname="selectGray"
            options={dummyCurrencies}
            selectedOption={getSelectedValue(dummyCurrencies, data.currency)}
            handleChangeSelect={(e) => handleSelect(e)}
          />
          <div className={styles.info}>
            <Input
              label={
                t.containerManagement.create.text_input_price_per_use_store
              }
              error={error.pricing}
              name="pricing"
              inputClass="inputForm"
              type="number"
              placeholder="0"
              value={data.pricing}
              desc={data.currency}
              onChange={handleChange}
              onKeyPress={handleInputNumber}
            />
            <Input
              label={t.containerManagement.create.text_input_late_fee}
              error={error.late_fee}
              name="late_fee"
              inputClass="inputForm"
              type="number"
              value={data.late_fee}
              placeholder="0"
              desc={data.currency}
              onChange={handleChange}
              onKeyPress={handleInputNumber}
            />
            <Input
              label={t.containerManagement.create.text_input_price}
              error={error.price}
              name="price"
              inputClass="inputForm"
              type="number"
              value={data.price}
              placeholder="0"
              desc={data.currency}
              onChange={handleChange}
              onKeyPress={handleInputNumber}
            />
            <Input
              label={t.containerManagement.create.text_input_price_per_use}
              error={error.pricePerUse}
              name="pricePerUse"
              inputClass="inputForm"
              type="number"
              value={data.pricePerUse}
              placeholder="0"
              desc={data.currency}
              onChange={handleChange}
              onKeyPress={handleInputNumber}
            />
          </div>
          <div className={styles.info}>
            <Input
              label={"Waste avoided"}
              error={error.wasteCoef}
              name="wasteCoef"
              inputClass="inputForm"
              type="number"
              placeholder="0"
              value={data.wasteCoef}
              desc={"kg"}
              onChange={handleChange}
              onKeyPress={handleInputNumber}
            />
            <Input
              label={"Water savings"}
              error={error.waterCoef}
              name="waterCoef"
              inputClass="inputForm"
              type="number"
              value={data.waterCoef}
              placeholder="0"
              desc={"L"}
              onChange={handleChange}
              onKeyPress={handleInputNumber}
            />
            <Input
              label={"Carbon emissions savings"}
              error={error.ghgCoef}
              name="ghgCoef"
              inputClass="inputForm"
              type="number"
              value={data.ghgCoef}
              placeholder="0"
              desc={"kg"}
              onChange={handleChange}
              onKeyPress={handleInputNumber}
            />
          </div>
          <div className={styles.options}>
            <Button
              label={t.button.text_cancel}
              btnClass="btnWhiteBackground"
              type="btn"
              onClick={handleCancel}
            />
            <Button
              btnClass="btnNormalCasal"
              label={t.button.text_save}
              type="submit"
              disabled={disabled}
            />
            {editing && (
              <Button
                btnClass="btnNormal"
                label={t.button.text_delete}
                onClick={openDeleteModal}
              />
            )}
          </div>
        </form>
      ) : (
        <div className={styles.noData}>
          <span> Select container to view details</span>
        </div>
      )}
      {errorMsg ? (
        <div className={styles.error}>
          <p>{t.common.text_all_fields_required}</p>
        </div>
      ) : null}
    </>
  );
};

export default CreateTypeForm;
