import React, { useEffect, useState } from "react";
import styles from "./restaurant.module.scss";
import { useDispatch, useSelector } from "react-redux";

import Button from "../../button";
import { modalActions } from "../../../_store/actions/modal.actions";
import StoresCheckoutLog from "../header/storeCheckoutLog";
import t from "../../../_config/constants";
import DownloadLogsStatistics from "./downloadLogsStatistics";
import CheckoutsChart from "../../statistics/checkoutsChart";
const DashboardRestaurant = () => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const [selectedRestaurant, setSelectedRestaurant] = useState("");
  const [downloadLogsFilter, setDownloadLogsFilter] = useState({
    period: "daily",
    startOfWeek: "",
    month: "",
    year: "",
  });
  useEffect(() => {
    setSelectedRestaurant("");
  }, [store.auth && store.auth.user]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h2>Containers Rental</h2>
        <div className={styles.buttons}>
          <DownloadLogsStatistics filter={downloadLogsFilter} />
          <Button
            label={t.restaurant.text_stores_checkout_feed}
            btnClass="btnWhiteBackground"
            onClick={() =>
              dispatch(
                modalActions.openModal(
                  <StoresCheckoutLog restaurantId={selectedRestaurant} />,
                  "",
                  ""
                )
              )
            }
          />
        </div>
      </div>
      <div className={styles.chartWrapper}>
        <CheckoutsChart
          setSelectedRestaurant={setSelectedRestaurant}
          setLogsDate={setDownloadLogsFilter}
        />
      </div>
    </div>
  );
};

export default DashboardRestaurant;
