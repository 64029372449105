import React, { useState, useEffect } from "react";
import styles from "./regionNotifications.module.scss";
import Button from "../../button";
import Textarea from "../../textarea";
import { useDispatch } from "react-redux";
import { mainActions } from "../../../_store/actions/main.actions";
import Loading from "../../loading";
import toastService from "../../../_services/toast.service";
import t from "../../../_config/constants";
import { isObjectEmpty } from "../../../_utils/utils";
import trimObjectProps from "../../../_utils/trimObjectProps";
const RegionNotifications = ({
  onRegionClick,
  onSubRegionClick,
  onAdminClick,
  onNotificationsClick,
  onPostalCodesClick,
  activeTab,
}) => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState({});
  const [daysToReturnArr, setDaysToReturnArr] = useState({});
  const [loading, setLoading] = useState(true);
  const [subregions, setSubregions] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [selectedNotifications, setSelectedNotifications] = useState({});
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const subregions = await dispatch(
        mainActions.run("data", "subregion", "all")
      );
      setSubregions(subregions);
      await fetchNotifications();
      setLoading(false);
    };
    fetchData();
  }, []);
  const fetchNotifications = async () => {
    let data = await dispatch(
      mainActions.run("data", "subregionNotifications", "all", {})
    );
    setNotifications(data);
  };
  const handleChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    setSelectedNotifications((selected) => ({
      ...selected,
      [name]: value,
    }));
  };

  const handleCancel = () => {
    setSelected({});
    setSelectedNotifications({});
    setDaysToReturnArr({});
  };

  const handleSubmit = async () => {
    trimObjectProps(selectedNotifications);
    setDisabled(true);

    if (!isObjectEmpty(selectedNotifications)) {
      if (!selectedNotifications.subregionId) {
        selectedNotifications.subregionId = selected._id + "";
      }
      let response = await dispatch(
        mainActions.run(
          "data",
          "subregionNotifications",
          selectedNotifications._id ? "update" : "new",
          selectedNotifications
        )
      );
      if (response)
        toastService.show("success", t.toast.text_successfully_submited);
    }

    await fetchNotifications();
    handleCancel();
    setDisabled(false);
  };

  const handleSelected = (item) => {
    setSelected(item);
    const found = notifications.find(
      (notification) => notification.subregionId == item._id
    );
    setSelectedNotifications(found || {});

    let arr = [-1, 0, 1, 3, 7, 10, 15];

    arr = arr
      .filter((i) => i < item.days_to_return)
      ?.map((ind) => {
        let visible = found?.[ind];
        return {
          day: ind,
          visible: visible,
        };
      });
    setDaysToReturnArr(arr);
  };

  const handleShowTextArea = (item) => {
    let arr = [...daysToReturnArr];
    let el = arr.find((arrItem) => arrItem.day === item.day);
    el.visible = !el.visible;
    setDaysToReturnArr(arr);
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.left}>
        <div className={styles.header}>
          <p
            className={
              activeTab === "region" ? styles.tabActive : styles.tabInactive
            }
            onClick={onRegionClick}
          >
            {t.admin.regions.text_regions}
          </p>
          <p
            className={
              activeTab === "subregion" ? styles.tabActive : styles.tabInactive
            }
            onClick={onSubRegionClick}
          >
            {t.admin.regions.text_subregions}
          </p>
          <p
            className={
              activeTab === "codes" ? styles.tabActive : styles.tabInactive
            }
            onClick={onPostalCodesClick}
          >
            {t.admin.regions.text_region_postal_codes}
          </p>
          <p
            className={
              activeTab === "notifications"
                ? styles.tabActive
                : styles.tabInactive
            }
            onClick={onNotificationsClick}
          >
            {t.admin.regions.text_region_notifications}
          </p>
          <p
            className={
              activeTab === "admin" ? styles.tabActive : styles.tabInactive
            }
            onClick={onAdminClick}
          >
            {t.admin.admins.text_admins}
          </p>
        </div>
        <h2>{t.admin.regions.text_list_of_regions}</h2>
        {loading && <Loading width={50} height={50} />}
        {subregions && subregions.length > 0 && (
          <div className="custom-table">
            <table>
              <thead>
                <tr className="tb-head-th">
                  <th>Name</th>
                  <th>{t.admin.regions.text_th_return_days}</th>
                </tr>
              </thead>
              <tbody>
                {subregions?.map((item, index) => (
                  <React.Fragment key={item._id}>
                    <tr
                      onClick={() => handleSelected(item)}
                      className={
                        selected._id === item._id ? styles.selected : ""
                      }
                    >
                      <td>{item.name}</td>
                      <td>{item.days_to_return}</td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {!loading && subregions.length < 1 && (
          <p>{t.admin.notifications.text_no_notifications}</p>
        )}
      </div>
      <div className={styles.right}>
        {!isObjectEmpty(selected) ? (
          <>
            <h2>{t.admin.notifications.text_edit}</h2>
            <div className={styles.content}>
              {daysToReturnArr.length > 0 &&
                daysToReturnArr?.map((item) => (
                  <div key={item.day} className={styles.textarea}>
                    <label
                      onClick={() => {
                        handleShowTextArea(item);
                      }}
                    >
                      <span>
                        {item.day === 0
                          ? "Due Date"
                          : item.day < 0
                          ? "Overdue (sent every day)"
                          : `${item.day} ${
                              item.day === 1 ? "day" : "days"
                            } before the due date`}
                      </span>
                      <div className={styles.plus}>
                        {item.visible ? "-" : "+"}
                      </div>
                    </label>
                    {item.visible && (
                      <Textarea
                        name={`${item.day}`}
                        textareaClass="textareaDefault"
                        type="text"
                        onChange={handleChange}
                        value={selectedNotifications?.[item.day]}
                      />
                    )}
                  </div>
                ))}
            </div>
            <div className={styles.options}>
              <div className={styles.submit}>
                <Button
                  btnClass="btnWhiteBackground"
                  label={t.button.text_cancel}
                  onClick={handleCancel}
                />
                <Button
                  btnClass="btnNormalCasal"
                  label={t.button.text_save}
                  onClick={handleSubmit}
                  disabled={disabled}
                />
              </div>
            </div>
          </>
        ) : (
          <div className={styles.noData}>
            <span>{t.admin.notifications.text_select_guideline} </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default RegionNotifications;
