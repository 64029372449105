import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import styles from "./line.module.scss";
import { createCumulativeArray } from "../../../_utils/utils";

const LineChart = React.memo(({ title, arrayForLineChart, height }) => {
  let [lineLabels, setLineLabels] = useState([]);
  const [lineData, setLineData] = useState([]);
  const [cumulativeData, setCumulativeData] = useState([]);
  useEffect(() => {
    const valueArr = [];
    let cumulativeValueArr = [];
    for (let i = 0; i < arrayForLineChart.length; i++) {
      valueArr.push(arrayForLineChart[i].value);
    }

    cumulativeValueArr = createCumulativeArray(arrayForLineChart || []);
    setLineData(valueArr);
    setCumulativeData(cumulativeValueArr);
  }, [arrayForLineChart]);

  lineLabels = [...new Set(arrayForLineChart.map((obj) => obj.labels))];

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
    },
    scales: {
      y: {
        grid: {
          lineWidth: 0.5,
          drawBorder: false,
        },
        beginAtZero: true,
        ticks: {
          callback: function (value, index, values) {
            return value;
          },
          color: "#43425D",
          font: 12,
          padding: 20,
          precision: 0,
        },
      },
      x: {
        grid: {
          lineWidth: 0.5,
          drawBorder: false,
        },
        ticks: {
          color: "#43425D",
          font: 12,
          padding: 20,
        },
      },
    },
  };

  const data = {
    labels: lineLabels,
    datasets: [
      {
        minHeight: 50,
        label: "Per period",
        data: lineData,
        fill: false,
        lineTension: 0.4,
        backgroundColor: "#2B5B6C",
        pointBackgroundColor: "#fff",
        pointBorderWidth: "2",
        borderColor: "#2B5B6C",
      },
      {
        minHeight: 50,
        label: "Cumulative",
        data: cumulativeData,
        fill: false,
        lineTension: 0.4,
        backgroundColor: "#e2725b",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 2,
        borderColor: "#e2725b",
      },
    ],
  };
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.title}>{title}</div>
        <Line
          className={styles.bigChart}
          height={height || 320}
          width={500}
          data={data}
          options={options}
        />
      </div>
    </>
  );
});

export default LineChart;
