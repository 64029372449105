const moment = require("moment");

export const getDropdownListMonths = (selectedYear) => {
  let date = new Date();
  let arr = [];
  let months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  if (selectedYear == 2022 && date.getFullYear() == 2022) {
    for (let i = date.getMonth(); i >= 9; i--) {
      arr.push({
        label: months[i],
        value: new Date(selectedYear, i),
      });
    }
  } else if (selectedYear == 2022 && date.getFullYear() != 2022) {
    for (let i = 11; i >= 9; i--) {
      arr.push({
        label: months[i],
        value: new Date(selectedYear, i),
      });
    }
  } else if (selectedYear != date.getFullYear()) {
    for (let i = 11; i >= 0; i--) {
      arr.push({
        label: months[i],
        value: new Date(selectedYear, i),
      });
    }
  } else {
    for (let i = date.getMonth(); i >= 0; i--) {
      arr.push({
        label: months[i],
        value: new Date(selectedYear, i),
      });
    }
  }
  return arr;
};
export const getDropdownListYears = (minYear = 2022) => {
  let date = new Date();
  let arr = [];

  for (let i = date.getFullYear(); i >= minYear; i--) {
    arr.push({
      label: i,
      value: i,
    });
  }

  return arr;
};

export const getWeeksOfYear = (year) => {
  const weeks = [];
  let date = moment().year(year).startOf("year").startOf("isoWeek"); // First Monday of the year
  if (date.year() !== year) {
    date = date.add(1, "week");
  }

  if (date.isAfter(moment())) {
    // Start Date of this week range is in the future, reverse one week
    const endDate = date;
    const previousWeekStart = date.clone().subtract(7, "days");
    weeks.push({
      label: `${previousWeekStart.format("MM/DD")}-${endDate.format("MM/DD")}`,
      value: previousWeekStart.valueOf(), // Start of the week in milliseconds
    });
  }

  while (date.year() === year && date.valueOf() <= new Date().getTime()) {
    const endDate = date.clone().add(6, "days"); // End of the current week
    weeks.push({
      label: `${date.format("MM/DD")}-${endDate.format("MM/DD")}`,
      value: date.valueOf(), // Start of the week in milliseconds
    });
    date.add(1, "week"); // Move to the next Monday
  }
  weeks.reverse();
  return weeks;
};
